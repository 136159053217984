import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Social from '../social/social';
import throttle from 'lodash/throttle';
import { canUseDOM } from 'exenv';

class MicMobileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socialActive: false
    };

    this.toggleShare = this.toggleShare.bind(this);
    this.onScroll = throttle(this.onScroll.bind(this), 300);
  }

  componentWillMount() {
    if (canUseDOM) {
      window.addEventListener('scroll', this.onScroll);
    }
  }

  componentWillUnmount() {
    if (canUseDOM) {
      window.removeEventListener('scroll', this.onScroll);
    }
  }

  onScroll() {
    if (this.state.socialActive) {
      this.setState({
        socialActive: false
      });
    }
  }

  toggleShare() {
    this.setState({
      socialActive: !this.state.socialActive
    });
  }

  render() {
    const { scrollToSection, top50Ref, sectorsRef } = this.props;
    const { socialActive } = this.state;

    return (
      <div className="mic-mobile-menu">
        <div className="mic-mobile-menu__item-container">
          <div
            className="mic-mobile-menu__item"
            onClick={() => scrollToSection(top50Ref, 50)}
          >
            TOP 50
          </div>
          <div
            className="mic-mobile-menu__item"
            onClick={() => scrollToSection(sectorsRef, 50)}
          >
            SECTORS
          </div>
          <div
            className="mic-mobile-menu__item mic-mobile-menu__item--share"
            onClick={() => this.toggleShare()}
          />
        </div>
        <div
          className={`mic-mobile-menu__social ${
            socialActive ? 'mic-mobile-menu__social--active' : ''
          }`}
        >
          <Social
            empty={false}
            enableSticky={false}
            modifier="mic-2020-mobile"
            uri="https://www.fastcompany.com/most-innovative-companies/2020"
            text={{
              twitter: encodeURIComponent(
                `Meet @FastCompany's Most Innovative Companies 2020 #FCMostInnovative`
              ),
              linkedin: encodeURIComponent(
                `Meet FastCompany's Most Innovative Companies 2020`
              ),
              email: encodeURIComponent(
                `Meet FastCompany's Most Innovative Companies 2020`
              )
            }}
            subject="Fast Company’s 50 Most Innovative Companies of 2020."
          />
        </div>
      </div>
    );
  }
}

MicMobileMenu.propTypes = {
  top50Ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  sectorsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]).isRequired,
  scrollToSection: PropTypes.func.isRequired
};

export default MicMobileMenu;
